import axios from "axios";
import { getUser } from "../utils/localStorage";

const API = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

API.interceptors.request.use((req) => {
  const user = getUser();

  if (user?.token) {
    req.headers.Authorization = `Bearer ${user.token}`;
  }

  return req;
});

const APPLICANT_API = axios.create({
  baseURL: process.env.REACT_APP_APPLICANT_API,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

APPLICANT_API.interceptors.request.use((req) => {
  const user = getUser();

  if (user?.token) {
    req.headers.Authorization = `Bearer ${user.token}`;
  }

  return req;
});

const HELPDESK_API = axios.create({
  baseURL: process.env.REACT_APP_HELPDESK_API,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

HELPDESK_API.interceptors.request.use((req) => {
  const user = getUser();

  if (user?.token) {
    req.headers.Authorization = `Bearer ${user.token}`;
  }

  return req;
});

// get methods starts
export const getCountries = (controller) =>
  API.get(`/employee/country`, { signal: controller.signal });
export const getRegion = (id, controller) =>
  API.get(`/employee/region/${id}`, { signal: controller.signal });
export const getState = (id, controller) =>
  API.get(`/employee/state/${id}`, { signal: controller.signal });
export const getCity = (id, controller) =>
  API.get(`/employee/city/${id}`, { signal: controller.signal });
export const getCityByPostal = (postal, controller) =>
  API.get(`/employee/city-by-postal/${postal}`, { signal: controller.signal });
export const fetchLeaveList = (token, controller) =>
  API.get("/employee/configuration/leave", { headers: { Authorization: `Bearer ${token}` } }, { signal: controller.signal });
export const fetchLeaveCredit = (controller) =>
  API.get("/employee/leave/list", { signal: controller.signal });
export const getProfile = (token, controller) =>
  API.get(`/employee/profile/get`, { headers: { Authorization: `Bearer ${token}` } }, { signal: controller.signal });
export const getAttendanceLog = (params, controller) =>
  API.get(`/employee/attendance-log/get${params}`, { signal: controller.signal });
export const getEmployeeDocument = (
  user_id,
  archive,
  page,
  entries,
  search,
  controller
) =>
  API.get(
    `/employee/document`,
    // /${user_id}/${archive}?page=${page}&paginate=${entries}&search_input=${search}`,
    { signal: controller?.signal }
  );
export const getManageAttendance = (controller) =>
  API.get(`/employee/manage-attendance`, { signal: controller.signal });
// get methods end

// put methods starts
export const setVisitNotification = (id, token) =>
  APPLICANT_API.put(`/notification/${id}`, null, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const updateProfile = (params, token) =>
  API.put("/employee/profile/update", params, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const updateEmployeeFolder = (id, params) =>
  APPLICANT_API.put(`/employee/folder/${id}`, params);
export const updateEmployeeDocument = (id, params) =>
  APPLICANT_API.put(`/employee/folder/update/document/${id}`, params);
export const agreeOnboarding = (id) =>
  API.put(`/employee/onboard/agreed/${id}`);
// put methods end

// post methods starts
export const loginUser = (params) => API.post("/login", params);
export const attendanceLog = (token, params) => API.post("/employee/attendance-log/save", params, {
  headers: { Authorization: `Bearer ${token}` },
});
export const saveLeaveFiling = (params) => API.post("/employee/filling/save", params);
export const forgotPassword = (params) => API.post("/forgot-password", params);
export const resetPassword = (params) => API.post("/reset-password", params);
export const changeFirstLoginPassword = (id, params) => API.post(`/change-tmp-password/${id}`, params);
export const verifyOtp = (params) => API.post("/verify-twofa", params);
export const resendOtp = (params) => API.post("/resend-otp", params);
export const saveEmployeeFolder = (formData) =>
  APPLICANT_API.post("/employee/folder", formData);
export const saveEmployeeDocument = (formData) =>
  API.post("/employee/document", formData);
// post methods end

// delete methods starts
export const deleteEmployeeDocument = (id) =>
  API.delete(`/employee/document/${id}`);
export const deleteEmployeeFolder = (id, type) =>
  APPLICANT_API.delete(`/employee/folder/${id}/${type}`);

// My Leaves
export const getMyLeaves = (type, params, controller) =>
  API.get(`/employee/filling/list/${type}?${params}`, { signal: controller.signal });
export const getLeaveData = (id, controller) =>
  API.get(`/employee/filling/get/${id}`, { signal: controller.signal });
export const cancelMyLeaves = (id) =>
  API.put(`/employee/filling/update/${id}`);
export const followUpRequest = (id) =>
  API.post(`/employee/filling/notify/${id}`);

// My COA
export const getMyCOA = (type, params, controller) =>
  API.get(`/employee/filling/coa/list/${type}?${params}`, { signal: controller.signal });
export const getCoaData = (id, controller) =>
  API.get(`/employee/filling/coa/get/${id}`, { signal: controller.signal });
export const updateCoaFiling = (id, params) =>
  API.put(`/employee/filling/coa/update/${id}`, params);

// My SA
export const getMyScheduleAdjustment = (type, params, controller) =>
  API.get(`/employee/filling/sa/list/${type}?${params}`, { signal: controller.signal });
export const getScheduleAdjustmentData = (id, controller) =>
  API.get(`/employee/filling/sa/get/${id}`, { signal: controller.signal });
export const updateScheduleAdjustment = (id, params) =>
  API.put(`/employee/filling/sa/update/${id}`, params);

// My Overtime
export const getMyOvertime = (type, params, controller) =>
  API.get(`/employee/filling/ot/list/${type}?${params}`, { signal: controller.signal });
export const getOvertimeData = (id, controller) =>
  API.get(`/employee/filling/ot/get/${id}`, { signal: controller.signal });
export const updateOvertime = (id, params) =>
  API.put(`/employee/filling/ot/update/${id}`, params);
export const getShift = (shift) =>
  API.get(`/employee/filling/ot/shift?shift=${shift}`);
export const saveOTFiling = (params) =>
  API.post(`/employee/filling/ot/save`, params);

// My OB
export const getMyOB = (type, params, controller) =>
  API.get(`/employee/filling/ob/list/${type}?${params}`, { signal: controller.signal });
export const getObData = (id, controller) =>
  API.get(`/employee/filling/ob/get/${id}`, { signal: controller.signal });
export const updateObFiling = (id, params) =>
  API.put(`/employee/filling/ob/update/${id}`, params);
export const saveOBFiling = (params) =>
  API.post(`/employee/filling/ob/save`, params);

// Asset
export const getMyAsset = (params, controller) =>
  API.get(`/employee/asset/list?${params}`, { signal: controller.signal });
export const assetConfig = (controller) =>
  API.get("/employee/asset/config", { signal: controller.signal });
export const requestAsset = (params) =>
  API.post("/employee/asset/save", params);
export const updateAsset = (id, params) =>
  API.put(`/employee/asset/update/${id}`, params);
export const uploadAssetForm = (id, params) =>
  API.put(`/employee/asset/upload-forms/${id}`, params);

// Profile Information
export const getHiredInformation = (controller) =>
  API.get(`/employee/profile/information/get`, { signal: controller?.signal });
export const updateHiredInformation = (type, params) =>
  API.put(`/employee/profile/information/update/${type}`, params);
export const filterWorkSchedule = (params, controller) =>
  API.get(`/employee/profile/work-schedule/get?${params}`, { signal: controller?.signal });

// Attendance Summary
export const getAttendanceSummary = (params, controller) =>
  API.get(`/employee/attendance-log/summary?${params}`, { signal: controller.signal });

// Task List
export const getTaskList = (controller) =>
  API.get(`/employee/task/list`, { signal: controller.signal });
export const getTask = (id) =>
  API.get(`/employee/task/get/${id}`);
export const updateTask = (id, params) =>
  API.put(`/employee/task/update/${id}`, params);

// Support Ticketing
export const getTicketList = (params, controller) =>
  HELPDESK_API.get(`/faq?${params}`, { signal: controller.signal });
export const getTicket = (id) =>
  HELPDESK_API.get(`/faq/${id}`);
export const requestTicket = (params) =>
  HELPDESK_API.post("/faq", params);
export const updateTicket = (id, params) =>
  HELPDESK_API.put(`/faq/${id}`, params);

// Referral
export const getReferralList = (params, controller) =>
  API.get(`/employee/referral/list?${params}`, { signal: controller.signal });

// Offboarding Clearance
export const getOffboardingClearance = (controller) =>
  API.get(`/employee/clearance/get`, { signal: controller.signal });
export const getOffboardingClearanceDetail = (id) =>
  API.get(`/employee/clearance/accountability/get/${id}`);
export const followUpClearance = (id) =>
  API.put(`/employee/clearance/notify/${id}`);
export const uploadClearanceFile = (params) =>
  API.post("/employee/clearance/accountability/upload", params);

// Timelock
export const getTimesheetLock = () =>
  API.get(`/employee/timesheet/lock`);

// Calendar
export const getCalendarEvents = (params) =>
  API.get(`/employee/calendar${params}`);

// Reimbursements
export const requestReimbursement = (params) =>
  API.post("/employee/reimbursement/save", params);
export const getMyReimbursement = (params, controller) =>
  API.get(`/employee/reimbursement/list?${params}`, { signal: controller.signal });
export const cancelReimbursement = (id) =>
  API.put(`/employee/reimbursement/cancel/${id}`);
export const getReimbursementData = (id, controller) =>
  API.get(`/employee/reimbursement/get/${id}`, { signal: controller.signal });
export const updateReimbursement = (id, params) =>
  API.put(`/employee/reimbursement/update/${id}`, params);
export const followUpReimbursement = (id) =>
  API.post(`/employee/reimbursement/notify/${id}`);